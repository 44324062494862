<template>
  <div v-bind:style="styles">
    <div id="page-content"  v-if="!viLoader">
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        ALERT AND NOTIFICATION
       </h4>
     </div>
      <div class="row mt-2 ml-2 mr-2">
        <div class="col-lg-12">
          <div class="widjet">
           
            <div class="p-3 widjetcontent">
               
                  <div class="widjetcontent mt-1">
                    <div>
                       <div class="kidswrapper p-3" style="background-color: #efe6e6;" v-for="(data, index) in alertList" :key="index">
                        <div class="row">
                          <div class="col-12">
                            <div class="kids-details-box" style="display: grid;">
                             Subject         :  {{ data.subject || '' }} <br> Event Type       :  {{ data.eventType || '' }} <br> Date And Time:  {{ data.createdDate | dateAndTimeFormat }}  
                             <br>
                           
                              <div :style="overviewHight == true ? 'overflow: hidden;' : 'height: 80px;overflow: hidden;'"
                                disabled
                                v-html="data.content"
                                
                              >
                              
                              </div> 
                              <span v-if="!overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="increaseOverViewheight">Read More</span>
                                <!-- style="margin-bottom: 1%;padding-top: 1%;"  -->
                                <!-- <a v-if="!overviewHight && data.content && data.content.length > 540" href="javascript:void(0);" style="margin-left: 39%;" @click="increaseOverViewheight">Read More</a> -->
                             <span v-if="overviewHight && data.content && data.content.length > 540"  style="margin-left: 39%;color: blue;cursor: pointer;" @click="reduceOverViewheight">Read Less</span>
                               <br>
                               <span v-for="(link, index) in data.linkList" :key="index" >
                               <a v-if="link.name && link.value" href="javascript:void(0);" class="txtellipsis"   @click.prevent="navigatetolink(link.value)">Link {{ index + 1 }} :  {{ link.name }} 
                               </a>
                            </span>
                             <div class="form-row" style="width: 100%;" v-for="(doc, indexDoc) in data.documentList" :key="indexDoc">
                            <div class="form-group input-group col-md-4 mt-4">
                              <span class="has-float-label">
                                <input 
                                  v-model="doc.name"
                                  disabled
                                  class="form-control form-input"
                                  id="fpstartdateid"
                                />
                                <label  for="fpstartdateid">File Name</label>
                              </span>
                            </div>
                            <div class="form-group input-group col-md-4 mt-4">
                                                          <a :href="doc.url" target="_blank" >Download </a>

                            
                            </div>
                        </div>
                            </div>
                          
                          
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                
          </div>
        </div>
      </div>
    </div>
   
  </div>
   <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      alertList: [],
      viLoader: false,
      overviewHight: false
    };
  },
  created() {
    this.getAlertList()

  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    }
  },

  methods: {
    increaseOverViewheight(){
      this.overviewHight = true
    },
     reduceOverViewheight(){
      this.overviewHight = false
    },
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
   //  this.$router.go(-1)
     this.$router.push({ path: `/parent/student/home/${this.$route.params.id}` });
    },
      navigatetolink(value){
      window.open(value, "_blank");
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     async getAlertList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
         this.viLoader = true
        const response = await ViService.viXGet(`/contact/getalertListAndReadForstudent?studentId=${this.$route.params.id}`,userData.token);

        if (response.isSuccess) {
          this.alertList = secureUI.secureGet(response.data);
        //  this.alertList = this.alertList.concat(this.alertList)
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false

      }
    },
  },
   components: {
    ViSpinner
  }
};
</script>
<style scoped>
ul,
ul li,
p,
div,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoice {
  margin: 0 auto;
  width: 999px;

  clear: both;
  position: relative;
  background: #fff;
}
.feedate {
  font-size: 0.78rem;
  text-align: right;
  margin: 0 auto;
  padding: 10px 0;
  width: 999px;
}
table,
td,
th {
  border: 1px solid black;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* table{
         border-collapse:collapse;
         width:100%;
        clear:both;
      } */
</style>